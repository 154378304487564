<template lang="">
  <WalletForm
    @closeForm="closeForm()"
    @updateSuccess="updateSuccess()"
    :walletEdit="walletEdit"
    :isEditing="isEditing"
    @createdSuccess="createdSuccess()"
    :displayForm="displayForm"
  ></WalletForm>
  <v-container>
    <v-row  class="text-center mt-5  ml-5 mx-auto">
      <v-col cols="12" class="mx-auto">
        <v-card class="mx-auto">
          <v-card-title>
            <span class="float-left">{{ state ? $t("trashWallet") : "" }}</span>
            <v-btn
              @click="state ? (state = '') : (state = 'trash')"
              class="float-right ml-3"
              size="small"
              icon
              :color="state ? 'orange' : 'secondary'"
            >
              <v-icon color="white"> mdi mdi-delete</v-icon>
            </v-btn>
            <v-btn
              class="float-right"
              @click="showCreateForm()"
              size="small"
              :disabled="state != ''"
              icon
              :color="!displayForm ? 'success' : isEditing ? 'success' : 'secondary'"
            >
              <v-icon> {{ !displayForm ? "mdi mdi-plus" : "mdi mdi-arrow-up" }}</v-icon>
              <template v-if="isEditing"> | <v-icon>mdi mdi-plus</v-icon> </template>
            </v-btn>
          </v-card-title>
          <v-table  hover >
            <thead>
              <tr>
                <th class="text-center">Icon</th>
                <th class="text-center">Name</th>
                <th class="text-center">Total Money</th>
                <th class="text-center">Real Money</th>
                <th class="text-center">Currency</th>
                <th class="text-center">Publish</th>
                <th class="text-center">Expire Date</th>
                <th class="text-center">Created At</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in wallets" :key="item.id">
                <td>
                  <v-img elevation="10" class="hover-pointer" style="width: 50px; height: 50px" :src="`${baseUrl}/images/icons/${item.icon ? item.icon.value : defaultIcon}`">
                  </v-img>
                </td>
                <td><v-icon v-if="item.is_exclude" color="orange">mdi mdi-star</v-icon> {{ item.name }}</td>
                <td>{{ formatNumber(item.money_total) }}</td>
                <td>{{ formatNumber(item.money_real) }}</td>
                <td>{{ item.currency.name }}</td>
                <td>{{ item.is_public != 0 ? item.is_public : "Public" }}</td>
                <td>{{ item.expire_date ? item.expire_date : "---/---" }}</td>
                <td>{{ item.createdAt }}</td>
                <td>
                  <v-btn
                    :variant="item.is_exclude || state ? 'flat' : 'outlined'"
                    @click="changeStatusWallet(item.id, item.is_exclude || state ? 'forever' : 'removed')"
                    class="ma-1"
                    color="error"
                    icon="mdi-delete"
                    size="small"
                  ></v-btn>
                  <v-btn v-if="state" outlined @click="changeStatusWallet(item.id, 'recovery')" class="ma-1" icon color="orange" size="small">
                    <v-icon color="white"> mdi-sync</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!state"
                    :variant="walletEdit.id == item.id ? 'flat' : 'outlined'"
                    @click="showEditForm(item)"
                    color="info"
                    icon="mdi-pencil"
                    class="ma-1"
                    size="small"
                  ></v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import walletService from "@/services/wallet.service";
import { mapState } from "pinia";
import WalletForm from "./WalletForm.vue";
import { useTheme } from "vuetify";
import { useAuthStore } from "@/stores/authStore";
import { useToast } from "vue-toastification";
import { useWalletStore } from "@/stores/walletStore";
import { useConfigStore } from "@/stores/configStore";
export default {
  name: "MainWallet",
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    toast$: useToast(),
    walletStore$: useWalletStore(),
  }),
  components: {
    WalletForm,
  },
  data: () => ({
    displayForm: false,
    wallets: [],
    walletEdit: "",
    isEditing: false,
    loading: true,
    state: "",
  }),
  created() {},
  mounted() {
    // this.wallets = this.walletStore$.wallets;
    this.loadWallets();
    // this.wallets = this.walletStore$.wallets;
    // console.log(this.wallets)
    if (this.$route.query.showForm == "true") {
      this.displayForm = true;
    }
  },
  methods: {
    async loadWallets(status = "publish") {
      this.loading = true;
      await this.walletStore$.loadWalletsAndUser(status);
      this.wallets = this.walletStore$.wallets;
      this.loading = false;
    },
    showCreateForm() {
      if (this.displayForm == true) {
        if (this.isEditing) {
          this.isEditing = false;
          this.walletEdit = {};
        } else {
          this.displayForm = false;
        }
      } else {
        this.displayForm = true;
      }
    },
    showEditForm(item) {
      // if(this.displayForm){
      if (item.id == this.walletEdit.id) {
        this.isEditing = false;
        this.displayForm = false;
        this.walletEdit = "";
      } else {
        this.walletEdit = item;
        this.displayForm = true;
        this.isEditing = true;
      }
    },
    createdSuccess() {
      this.displayForm = false;
      this.isEditing = false;
      this.walletEdit = "";
      this.loadWallets();
    },
    closeForm() {
      this.displayForm = false;
      this.isEditing = false;
      this.walletEdit = "";
    },

    async changeStatusWallet(wallet_id, state = "remove") {
      var tempState = "removed";
      var flagConinute = true;
      if (state == "recovery") {
        tempState = "publish";
      } else if (state == "forever") {
        tempState = "forever";
      }

      if (tempState == "forever") {
        flagConinute = false;
        await this.$swal({
          title: this.$t("swalSureField", { action: this.$t("delete") }),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("ok"),
          cancelButtonText: this.$t("no"),
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            flagConinute = true;
          } else {
            return;
          }
        });
      }

      if (flagConinute) {
        var deleteRequest = await walletService.changeStatus(wallet_id, tempState);
        if (deleteRequest.success) {
          if (tempState == "recovery" || tempState == "removed") {
            this.toast$.success(this.$t("walletDeleteSuccess"));
          } else {
            this.state = "";
            this.toast$.success(this.$t("walletRecoverySuccess"));
          }

          this.loadWallets();
        } else {
          this.toast$.error(this.$t("failedRequest", { action: "delete", field: this.$t("SideBarWallet") }));
        }
      }
    },
    submitCreateWallet() {},
  },
  watch: {
    state: function (newValue) {
      if (newValue == "trash") {
        this.loadWallets("removed");
      } else {
        this.loadWallets();
      }
    },
  },
  computed: {},
};
</script>
