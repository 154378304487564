import LoginPage from "@/pages/LoginPage.vue";
import RegistrationPage from "@/pages/RegisterPage.vue";
import HomePage from "@/pages/HomePage.vue";
import MoneyPage from "@/pages/MoneyPage.vue";
import WalletPage from "@/pages/WalletPage.vue";
import PublicPage from "@/pages/PublicPage.vue";
import ChatPage from "@/pages/ChatPage.vue";
import NoPermissionPage from "@/pages/403Page.vue";
import AccountPage from "@/pages/AccountPage.vue";
import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "./stores/authStore";
import { useToast } from "vue-toastification";
import i18n from "@/langs/i18n";
import { ROLE_ADMIN, ROUTER_ACCOUNT, ROUTER_CHAT, ROUTER_CHAT_FRIEND, ROUTER_CHAT_INBOX, ROUTER_HOME, ROUTER_LOGIN, ROUTER_MONEY, ROUTER_PUBLIC, ROUTER_REGISTER, ROUTER_WALLET, ROUTE_403, ROUTE_NO_PERMISSION } from "./useConfig";
import { routesAdmin } from "./routesAdmin";
import ChatUI from '@/components/chat/ChatUI.vue'
import ChatFriend from '@/components/chat/ChatFriend.vue'
import ChatInbox from '@/components/chat/ChatInbox.vue'
export const routes = [
  {
    path: `/${ROUTER_LOGIN}`,

    name: ROUTER_LOGIN,
    component: LoginPage,
    meta: { titleI18n: "login", preventAccessLogin: true },
  },
  {
    path: `/${ROUTER_REGISTER}`,
    name: ROUTER_REGISTER,
    component: RegistrationPage,
    meta: { titleI18n: "register", preventAccessLogin: true },
  },
  {
    path: `/${ROUTER_ACCOUNT}`,
    name: ROUTER_ACCOUNT,
    component: AccountPage,
    meta: { titleI18n: "SideBarAccount", requiredLogin: true },
  },
  {
    path: `/${ROUTER_PUBLIC}/:key`,
    name: ROUTER_PUBLIC,
    component: PublicPage,
    meta: { titleI18n: "publicpage" },
  },
  {
    path: `/`,
    name: ROUTER_HOME,
    component: HomePage,
    redirect: (to) => {
      const authStore = useAuthStore();
      if (authStore.isLogin) {
        return { name: ROUTER_MONEY };
      }
      return { name: ROUTER_LOGIN };
    },
    meta: {
      title: "",
    },
  },
  {
    path: `/${ROUTER_MONEY}/:walletId?/:transactionId?`,
    name: ROUTER_MONEY,
    component: MoneyPage,
    meta: {
      titleI18n: "SideBarManagement",
      requiredLogin: true,
    },
  },
  {
    path: `/${ROUTER_WALLET}`,
    name: ROUTER_WALLET,
    component: WalletPage,
    meta: {
      titleI18n: "walletCreateTitle",
      requiredLogin: true,
    },
  },
  {
    path: `/${ROUTER_CHAT}`,
    name: ROUTER_CHAT,
    component: ChatUI,
    meta: {
      titleI18n: "chat",
      requiredLogin: true,
    },
    redirect: (to)=>{
      return { name: ROUTER_CHAT_INBOX };
    },
    children: [
      {
        path: ROUTER_CHAT_INBOX,
        name: ROUTER_CHAT_INBOX,
        component: ChatInbox,
      },
      {
        path: ROUTER_CHAT_FRIEND,
        name: ROUTER_CHAT_FRIEND,
        component: ChatFriend,
      },
    ]
  },



  //route exception
  {
    path: `/${ROUTE_403}`,

    name: ROUTE_403,
    component: NoPermissionPage,
    meta: { titleI18n: "noPermission", },
  },

  //admin router
  ...routesAdmin,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const DEFAULT_TITLE = "Trang chủ";

router.beforeEach((to, from, next) => {
  to.meta.title = i18n.global.t(to.meta.titleI18n);
  const authStore = useAuthStore();

  if (to.meta.preventAccessLogin) {
    if (authStore.isLogin) {
      router.push({ name: from.name });
    }
  }

  if (to.meta.requiredLogin || to.meta.admin) {
    if (!authStore.isLogin) {
      if(to.meta.admin) {
        router.push({ name: "login", query: {messageAdminNoRole:true} });
      }else{
        router.push({ name: "login", query: { backURL: to.name, ...router.query } });
      } 
    } else {
      if(to.meta.admin) {
        if(authStore.role != ROLE_ADMIN){
          router.push({ name:ROUTE_403});
        }
      }
    }
  }

  // if(to.meta.admin){
  //   if(authStore.role != ROLE_ADMIN) router.
  // }

  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = DEFAULT_TITLE;
  }

  next();
});

export { router };
