<template lang="">
  <v-card>
    <v-card-title> </v-card-title>
    <v-card-text>
      <!-- <v-btn @click="temp()">fdfds</v-btn> -->
      <v-table density="compact" block>
        <thead>
          <tr>
            <th class="text-left transaction-table-td">{{ $t("MoneyAddCategory") }}</th>
            <th class="text-left">{{ $t("MoneyAddCreateNote") }}</th>
            <th class="text-left">{{ $t("MoneyAddAmount") }}</th>
            <th class="text-left">{{ $t("MoneyAddCreateFile") }}</th>
            <th class="text-left">{{ $t("MoneyAddCreateDate") }}</th>
            <th v-if="!modePublic" class="text-left"></th>
          </tr>
        </thead>

        <tbody v-for="item in tables">
          <tr colspan="6">
            <td colspan="6" class="pa-0 hover-pointer">
              <v-avatar size="36px" class="ml-1">
                <v-img alt="Avatar" :src="`${baseUrl}/images/icons/${item.icon.value}`"></v-img>
              </v-avatar>

              <span class="text-subtitle-1 ml-2 text-cyan-lighten-1 font-weight-regular">
                <span v-if="configStore$.lang == LANG_VN">{{ item.name }} </span> <span v-else>{{ item.nameTranslate }}</span>
                <v-btn
                  @click="item.expanded = !item.expanded"
                  :icon="item.expanded ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'"
                  size="small"
                  variant="text"
                ></v-btn>
              </span>
            </td>
          </tr>
          <td :rowspan="item.transactions.length + 1"></td>
          <tr
            @click="$emit('tableActionDetail', transaction)"
            class="hover-td-table"
            :class="classActive(transaction.id)"
            v-if="item.expanded"
            v-for="(transaction, index) in item.transactions"
            :key="transaction"
          >
            <td class="font-weight-medium">{{ transaction.note }}</td>
            <td>
              <span class="font-weight-medium" :class="transaction.amount > 0 ? 'text-green' : 'text-red'"
                ><span v-if="transaction.amount > 0">+</span> {{ formatNumber(transaction.amount) }}</span
              >
            </td>
            <td>
              <v-chip size="small" v-if="transaction.images.length > 0" color="info" label text-color="white">
                <v-icon start icon="mdi-image"></v-icon>
                {{ transaction.images.length }}
              </v-chip>
              <v-chip size="small" color="info" v-if="transaction.description" class="ml-1 mr-1" label text-color="white">
                            <v-icon class="ma-auto" start icon="mdi-format-text"></v-icon>
                          </v-chip>
            </td>
            <td class="font-weight-medium">{{ transaction.createdDate }}</td>
            <td v-if="!modePublic" class="font-weight-medium">
              <v-icon color="saveRed" @click="$emit('tableActionRemove', transaction)">mdi mdi-delete</v-icon>
              <v-icon @click="$emit('tableActionEdit', transaction)" color="cyan">mdi mdi-pencil</v-icon>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { useConfigStore } from "@/stores/configStore";
import { useMoneyStore } from "@/stores/moneyStore";
import { useWalletStore } from "@/stores/walletStore";
import { usePublicStore } from "@/stores/publicStore";
import _ from "lodash";
export default {
  props: { modePublic: false },
  setup: () => ({
    walletStore$: useWalletStore(),
    moneyStore$: useMoneyStore(),
    configStore$: useConfigStore(),
    publicStore$: usePublicStore(),
  }),
  name: "MoneyTransactionTable",
  data() {
    return {
      tables: [],
      temp: false,
      transaction: "",
    };
  },
  created() {
    this.handleDataTransactions();
  },
  mounted() {},
  methods: {
    classActive(id){
      let css = "";
      if(this.modePublic){
        if(this.publicStore$.transaction?.id ==id){
          css = 'save-active-bg';
        }
      }else{
        if(this.moneyStore$.transaction?.id ==id){
          css = 'save-active-bg';
        }
      }
      return css;
    },
    handleDataTransactions() {
      let arrayCategories = [];
      var _this = this;
      let data = [];
      if (this.modePublic) {
        data = this.publicStore$.transactions;
      } else {
        data = this.moneyStore$.transactions;
      }
      if (data.length > 0) {
        data.map((item) => {
          arrayCategories.push({ ...item.category, transactions: [], expanded: true });
        });
        arrayCategories = _.uniqBy(arrayCategories, "id");
        if (arrayCategories.length > 0) {
          arrayCategories.map((item) => {
            var findObject = data.filter((x) => x.category_id == item.id);
            item.transactions = findObject;
          });
        }
      }
      this.tables = arrayCategories;
    },
  },
  watch: {
    expanded: function (newValue) {},
  },
};
</script>
<style></style>
