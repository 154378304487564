import Api from "@/utils/axios";

export default {
  register(data) {
    return Api.post("/register", data);
  },
  updateSetting(data) {
    return Api.post("/user/doAction", {action:'update-setting',...data});
  },
  login(data) {
    return Api.post("/login", data);
  },
  loadUser() {
    return Api.post("/user/doAction",{action:'user'});
  },
  logout(token) {
    return Api.post("/logout", { token: token });
  },
  changeInformationAccount(body) {
    return Api.post("/user/doAction", { action: "change-information-account", ...body });
  },
};
